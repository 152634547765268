module.exports={
"label.account.name":`Imię`,
"label.account.copy":`Skopiuj losowy kod weryfikacyjny znajdujący się w wiadomości email i kliknij łącze umożliwiające zmianę hasła w następnym kroku.`,
"label.account.phone.length":`Numer telefonu komórkowego musi zawierać co najmniej 7 znaków`,
"label.account.Start.trial":`GET STRTED`,
"label.account.employees1000":`1000  pracowników`,
"label.account.other.Ways":`Inne metody logowania`,
"label.account.emailsync.button.cancel":`Anuluj`,
"label.account.rule":`Składa się z 816 cyfr i liter.`,
"label.account.password.update.success":`Hasło zostało zmienione pomyślnie.`,
"label.account.describe":`CloudCC koncentruje się na cyfrowej personalizacji, analitycznej inteligencji i globalnej łączności, a także jest dostosowywany do potrzeb klienta wraz z rozwojem działalności przedsiębiorstwa.`,
"label.account.enter.Emailbox":`Wpisz swój adres email.`,
"label.account.password":`Hasło`,
"label.account.problem":`Logowanie nie powiodło się.`,
"label.account.Complete.reset":`Email. Wprowadź kod weryfikacyjny, aby dokończyć resetowanie hasła.`,
"label.account.crm.used":`CRM stosowany przez spółki giełdowe`,
"label.account.report.loading":``,
"label.account.notcustomer":`Brak konta?`,
"label.account.employees501":`5011000 pracowników`,
"label.account.title.operationManager":`Kierownik operacyjny`,
"label.account.Preset":`Wstępnie ustawione narzędzia do zarządzania projektami`,
"label.account.company":`Firma`,
"label.account.come":`Witamy na Platformie Sukcesu Klienta Cloudcc. Nasza nowa platforma oferuje najszybsze i najbardziej kompletne rozwiązanie, pozwalające robić wszystko z klientem w centrum uwagi.  `,
"label.account.input.password":`Wprowadź swoje hasło.`,
"label.account.emailobject.emaildetail.button.back":`Powrót`,
"label.account.title.itManager":`Kierownik IT`,
"label.account.Modified.successfully":`Zmiana hasła powiodła się. Kliknij przycisk zaloguj się ponownie, aby powrócić do strony logowania.`,
"label.account.only11":`Wprowadź co najmniej 7 cyfr.`,
"label.account.signin":`Logowanie`,
"label.account.register":`Zarejestruj się, aby otrzymać`,
"label.account.adopt":`Weryfikacja przebiegła pomyślnie!`,
"label.account.title.hrManager":`Kierownik ds. marketingu i kadr`,
"label.account.input.usernames":`Wpisz swoją nazwę użytkownika.`,
"label.account.only.Numbers":`Numer telefonu można wprowadzać tylko cyfry.`,
"label.account.title.salesManager":`Kierownik sprzedaży`,
"label.account.email":`Email:`,
"label.account.click":`Kliknij .`,
"label.account.and":`oraz`,
"label.account.mobile":`Telefon`,
"label.account.Privacy.policy":`Polityka prywatności`,
"label.account.password.isnull":`Hasło nie może być puste.`,
"label.account.employees101":`101500 pracowników`,
"label.account.remember.name":`Zapamiętaj nazwę użytkownika`,
"label.account.Select.country":`Wybierz swój kraj/region.`,
"label.account.distinguish":`W haśle rozróżniana jest wielkość liter.`,
"label.account.fillMobile":`Wpisz swój numer telefonu.`,
"label.account.Preconfigured":`Wstępnie ustawione raporty i pulpity nawigacyjne`,
"label.account.Select.job":`Proszę wybrać swoje stanowisko.`,
"label.account.employees21":`21100 pracowników`,
"label.account.username":`Nazwa użytkownika`,
"label.account.Submit":`Wyślij`,
"label.account.confirm":`Potwierdzenie konta`,
"label.account.contact":`Kontakt z nami`,
"label.account.country":`Kraj`,
"label.account.username.isnull":`Nazwa użytkownika nie może być pusta.`,
"label.account.forget.password":`Nie pamiętasz hasła?`,
"label.account.Loading":`Ładowanie`,
"label.account.goon":`Kontynuuj`,
"label.account.Register.trial":`Zarejestruj się, aby uzyskać bezpłatny okres próbny`,
"label.account.title.other":`Inne`,
"label.account.surname":`Nazwisko`,
"label.account.Fail":`Weryfikacja nie powiodła się, spróbuj ponownie.`,
"label.account.Terms.service":`Warunki świadczenia usług`,
"label.account.Select.staff":`Proszę wybrać liczbę pracowników.`,
"label.account.username.enter":`Wpisz swoje nazwisko.`,
"label.account.trial":`Bezpłatna próba`,
"label.account.employees1":`120 pracowników`,
"label.account.Quick.registration":`lub szybki dostęp`,
"label.account.tabpage.ok":`Potwierdź`,
"label.account.normal.reregister":`Zaloguj się ponownie`,
"label.account.batchadd.newbatchpage.save.em":`Format wiadomości email jest nieprawidłowy.`,
"label.account..user.title":`Nazwa stanowiska pracy`,
"label.account.No.credit.card.required":`Nie jest wymagana karta kredytowa.`,
"label.account.enterprise.Wechat":`WeCom`,
"label.account.sample.data":`Załaduj wstępnie dane próbki`,
"label.account.title.hobbies":`Zainteresowania`,
"label.account.platform":`Nowa inteligentna, mobilna i cyfrowa platforma CRM`,
"label.account.sent":`Wysłano wiadomość email`,
"label.account.Confirm.password":`Potwierdź swoje hasło.`,
"label.account.fill":`Proszę wypełnić wszystkie pola.`,
"label.account.user.resetpw":`Resetuj hasło`,
"label.account.Spell":`Przeciągnij suwak, aby ułożyć puzzle.`,
"label.account.identifying.code":`Wprowadź kod weryfikacyjny.`,
"label.account.staff":`Pracownicy`,
"label.account.welcome":`Witamy w CloudCC`,
"label.account.online.guidance":`Konsultacje i szkolenia online`,
"label.account.login.CloudCC":`Zaloguj się do CloudCC`,
"label.account.Company.name":`Wpisz nazwę swojej firmy.`,
"label.account.title.generalManager":`Dyrektor Generalny`,
"label.account.enter.user":`Aby zresetować hasło, wpisz swoją nazwę użytkownika CloudCC.`,
"label.account.sent.code":`Kod weryfikacyjny został wysłany do`,
"label.account.pw.error.text":`Dwa wprowadzone hasła nie pasują do siebie.`,
"label.account.title.customerManager":`Kierownik ds. obsługi klienta`,
"label.account.label.captcha":`Kod weryfikacyjny`,
"label.account.Trial.days":`Zarejestruj się na 30dniowy bezpłatny okres próbny`,
"label.account.please.enter.name":`Wpisz swoje imię.`,
"label.account.inspect":`Jeśli nie otrzymasz wiadomości email z potwierdzeniem kodu weryfikacyjnego, sprawdź spam.`,
"label.account.User.rule":`Nazwa użytkownika ma postać adresu email.`,
"label.account.go.Login":`Masz już konto?`,
"label.account.Reading.consent":`Wyrażam zgodę na`,
"label.account.Change.Password2":`Zmień hasło`,
"label.account.Loginby":`Zaloguj się przez .`,
"label.account.apply.partner":`Zgłoś się do programu partnerskiego CloudCC`,
"label.account.MobileLogin":`Mobile`,
"label.account.Registration":``,
"label.account.Italy":`Włochy`,
"label.account.view.details":`Zobacz szczegóły`,
"label.account.accept":`Zapoznaj się i zaakceptuj Warunki korzystania z usługi oraz Politykę prywatności.`,
"label.account.China.Mainland":`Mainland chiński`,
"label.account.NewPassword":`Wprowadź nowe hasło`,
"label.account.Mobile.not.empty":`Numer telefonu komórkowego nie może być pusty`,
"label.account.Please.select":`Proszę wybrać`,
"label.account.market.introduce":`Ujednolicona platforma kanałów marketingowych pomaga zdobyć więcej potencjalnych klientów`,
"label.account.IOS":`Zeskanuj, aby pobrać aplikację na iOS`,
"label.account.failsend":`Nie udało się wysłać`,
"label.account.UpperLowerlAphanumeric.character":`Wymagane są duże i małe litery, cyfry i symbole.`,
"label.account.New.Password1":`Nowe hasło`,
"label.account.succSend":`Została do Ciebie wysłana wiadomość email. Skopiuj kod weryfikacyjny znajdujący się w wiadomości email i zresetuj hasło.`,
"label.account.Account.mobile":`Numer konta lub telefonu komórkowego`,
"label.account.Login.consent":`Logując się, użytkownik wyraża zgodę na`,
"label.account.fillAreaCode":`Wprowadź numer kierunkowy`,
"label.account.active.login":``,
"label.account.jp":`Japonia`,
"label.account.partner.success1":`Pomyślnie zarejestrowałeś się jako partner CloudCC.`,
"label.account.Reset":`Resetuj`,
"label.account.no.user.info":``,
"label.account.EnterOldPass":`Proszę wprowadzić stare hasło`,
"label.account.Germany":`Niemcy`,
"label.account.title.content":`Zarejestruj się w CloudCC | 30dniowa bezpłatna wersja próbna  Wypróbuj teraz!`,
"label.account.Taiwan":`Tajwan, Chiny`,
"label.account.China.Station":`Chiny`,
"label.account.sale.introduce":`Automatyzacja sprzedaży pomaga wygrywać więcej transakcji`,
"label.account.Back":`Poprzedni Krok`,
"label.account.market":`Marketing Cloud`,
"label.account.section.company":`Dane firmy`,
"label.account.check.email":`nasz account executive skontaktuje się z Tobą za jakiś czas, sprawdź swoją pocztę w odpowiednim czasie.`,
"label.account.characters":`5 znaków`,
"label.account.International":`Międzynarodowa`,
"label.account.britain":`Zjednoczone Królestwo`,
"label.account.reg.fail.msg":`Rejestracja nie powiodła się. Prosimy spróbować ponownie później.`,
"label.account.success.reg":`udało Ci się zarejestrować`,
"label.account.company.url":`Strona internetowa`,
"label.account.CannotBeEmpty":`Nie może być pusty`,
"label.account.phone.note":``,
"label.account.second":`{0} sekund później`,
"label.account.Change.Password":`Zmień hasło`,
"label.account.Netherlands":`Holandia`,
"label.account.pleaseAgree":`Proszę zaakceptować i zgodzić się z Warunkami korzystania z usługi i Polityką prywatności.`,
"label.account.copyright":`CloudCC Inc. Wszelkie prawa zastrzeżone.`,
"label.account.letter":`1 litera`,
"label.account.remember":`Teraz pamiętam`,
"label.account.accountNum":`Konto`,
"label.account.code.not.empty":`Captcha nie może być pusta`,
"label.account.Spain":`Hiszpania`,
"label.account.Alphanumeric":`Wymagana jest kombinacja liter i cyfr`,
"label.account.obtain":`Przejęcie`,
"label.account.company.txt":`Proszę dodać swoją nazwę, jeśli nie masz zarejestrowanej firmy`,
"label.account.con.browser":``,
"label.account.Android":`Zeskanuj, aby pobrać aplikację na Androida`,
"label.account.title":`Bezpłatna próba CloudCC  CloudCC.com`,
"label.account.partner.success2":`Za jakiś czas odezwiemy się do Ciebie.`,
"label.account.Macao":`Makao, Chiny`,
"label.account.Brazil":`Brazylia`,
"label.account.service.introduce":`Jednolity proces obsługi zwiększa skuteczność reakcji`,
"label.account.codeFailure":`Nieprawidłowy kod weryfikacyjny`,
"label.account.AccountLogin":`Konto`,
"label.account.pswComplexReqRules":`Minimalna długość hasła wynosi {0}, a {1}`,
"label.account.UpperLowerlAphanumeric":`Wymagane są duże i małe litery oraz cyfry.`,
"label.account.active.username":``,
"label.account.rememberPassword":`Zapamiętaj hasło`,
"label.account.HongKong":`Honkong, Chiny`,
"label.account.oldPass":`Stare hasło`,
"label.account.Data.storage":`Dane użytkownika będą przechowywane w centrum danych {0}.`,
"label.account.number":`1 numer`,
"label.account.Poland":`Polska`,
"label.account.service":`Usługa Chmura`,
"label.account.Incorrect.format":`Nieprawidłowy format lub numer, wprowadź ponownie`,
"label.account.remember.status":`Pamiętaj o mnie`,
"label.account.sale":`Chmura sprzedaży`,
"label.account.No.account.binding":`Konto nie jest powiązane. Skontaktuj się z administratorem`,
"label.account.Russia":`Rosja`,
"label.account.France":`Francja`,
"label.account.partner.content":`CloudCC to globalna firma, która obecnie rekrutuje partnerów, aby odkrywać rozwiązania dla różnych branż. Dołącz do naszego programu partnerskiego!`,
"label.account.reg.phone.already":`Ta organizacja już istnieje dla tego telefonu. Zaloguj się lub użyj innego telefonu, aby się zarejestrować.`,
"label.account.Confirm":`Potwierdź nowe hasło`,
"label.account.VerificationLogin":`Zweryfikuj kod`,
"label.account.USA":`Stany Zjednoczone`,
"label.account.mobile.num":`Wprowadź numer telefonu komórkowego`,
"label.account.MinLength":`Minimalna długość hasła wynosi`,
"label.account.change.your.password":`Ze względów bezpieczeństwa prosimy o zmianę hasła.`,
"label.account.new.password":`Wprowadź nowe hasło dla {0}. Upewnij się, że zawiera ono co najmniej:`,
"label.account.website.enter":`Wpisz adres oficjalnej strony internetowej`,
"label.account.pswComplexIsZero":``
}